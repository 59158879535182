import metadata from "../locals/metadata.json"

export const ContactUs = ({ t }) => {
    return (
        <div className="p-4 w-full flex justify-center" id="contact_us">
            <div className="lg:space-y-0  lg:space-x-10 space-y-10 lg:w-3/4 lg:flex sm:flex">
                <div className="lg:hidden sm:hidden block ">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3440.4710007451795!2d-9.6185718!3d30.4227473!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xdb3b7bc6249705d%3A0xc0f2556b08e17163!2sAgadir%20Golden%20Trip%20-%20Excursion%20en%20bateau%20sur%20Agadir!5e0!3m2!1sen!2sma!4v1729960248577!5m2!1sen!2sma" className="w-full h-full" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div className="w-full space-y-5">
                    <h4 className="thirdary font-semibold text-2xl">{t["contactus_title"]}</h4>
                    <p>{t["contactus_description"]}</p>
                    <div>
                        <p><span><strong>{t["telephone_number"]}</strong>:{" " + metadata["phone_number"]}</span></p>
                        <p><span><strong>{t["email_adress"]}</strong>:{" " + metadata["email_adress"]}</span></p>
                        <p><span><strong>{t["address"]}</strong>:{" " + metadata["address"]}</span></p>

                    </div>

                    <div className="flex space-x-3">
                        {metadata["social_links"].map((social, index) => (
                            <a href={social.link} id={index} target="_blank" aria-label={social.name}>
                                <img src={social.src} alt={social.name} className="w-6 h-6" />
                            </a>
                        ))}
                    </div>
                    <div><a className="bg-green-400 p-4 text-black" href="https://www.viator.com/tours/Agadir/Boat-tour-in-Agadir/d4383-5543340P1" target="_blank" rel="noopener noreferrer">{t["cta_viator"]}</a>
                </div>            
                </div>
                <div className="lg:block sm:block hidden">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3440.4710007451795!2d-9.6185718!3d30.4227473!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xdb3b7bc6249705d%3A0xc0f2556b08e17163!2sAgadir%20Golden%20Trip%20-%20Excursion%20en%20bateau%20sur%20Agadir!5e0!3m2!1sen!2sma!4v1729960248577!5m2!1sen!2sma"className="w-full h-full" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"
                             title="Agadir Golden Trip Location"></iframe>
                </div>
            </div>

        </div>
    )
}