import { HelmetComponent } from './ArticleDetail'; // Ensure correct path or module import
import { NavBar } from '../components/NavBar';
import metadata from "../locals/metadata.json";
import React from "react";
import { WhatsAppFloating } from '../components/WhatsAppFloating';

export const Activities = ({ t }) => {
    return (
        <div className=" w-full flex justify-center" id="activities">
            <div className="space-y-10 ">
                <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-9">
                    {metadata["products_offered"].map((item, index) => (
                        <a className="space-y-2" href={t[item.slug]}>
                            <div className="">
                                <img loading="lazy" src={item.thumbnail} alt={t[item.page_title]} className="rounded-lg object-cover  w-[25rem] h-[17rem]" />
                            </div>
                            <div>
                                <h4 className="text-lg text-start text-blue-800 font-semibold">
                                    {t[item.page_title]}
                                </h4>
                                <p className="text-start text-[13px] text-gray-700">
                                    {t[item.page_description]}
                                </p>
                            </div>
                        </a>
                    ))}
                </div>
            </div>

        </div>
    )
}
export const ThingsTodo = ({ t, page_title, slug, page_description}) => {
  return (
    <div className="w-full h-full p-4">
      {/* Page Metadata */}
      <HelmetComponent page_title={page_title} slug={slug} page_description={page_description}/>

      {/* Floating WhatsApp Button */}
      <WhatsAppFloating />

      {/* Navbar */}
      <NavBar t={t} hide_cta={true}/>

      <div className="flex justify-center w-full pt-4 ">
        <div className="lg:w-2/3 h-auto">
            <div className="space-y-7">
                <div className="space-y-3">
                    <h1 className="text-4xl text-thirdary  font-semibold">{page_title}</h1>
                    <p>{page_description}</p>  
                </div>
                <Activities t={t}/>
            </div>
        </div>
      </div>

    </div>
  );
};
