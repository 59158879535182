import { useEffect } from "react";
 
export const About = ({ t }) => {
      useEffect(() => {
    // Ensure Facebook SDK is loaded
    if (window.FB) {
      window.FB.XFBML.parse();
    }
  }, []);
    return (
        <div className="p-5 flex justify-center items-center w-full text-center min-h-[300px]" id="about_us">

                <div className="space-y-4 lg:w-2/3 p-2">

                <h1 className='text-3xl font-semibold text-thirdary'>{t["head_1"]}</h1>
                <div className="sm:flex lg:flex sm:justify-center lg:justify-center items-center space-x-4">
                </div>

                <p className="leading-relaxed  text-[12px]  min-h-[40px] lg:text-sm">{t["paragraph_1"]}</p>
                <p className="font-bold">{t["paragraph_2"]}</p>
                <div><a className="bg-green-400 p-4 text-black" href="https://www.viator.com/tours/Agadir/Boat-tour-in-Agadir/d4383-5543340P1" target="_blank" rel="noopener noreferrer">{t["cta_viator"]}</a>
                </div>
            </div>
            </div>
    )
}