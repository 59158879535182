import { Head } from "./Heading"
import metadata from "../locals/metadata.json"
export const Products = ({ t }) => {
    return (
        <div className="p-4 w-full flex justify-center" id="offers">
            <div className="space-y-10 lg:w-3/4">
                <div className=" space-y-3 lg:w-1/2">
                    <Head title={t["head_2"]} />
                    <p className="text-[14px]">{t["paragraph_3"]}</p>
                </div>
                <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-9">
                    {metadata["products"].map((item, index) => (
                        <a className="" href={"https://agadirgoldentrip.com"+ t["cta_next"]}>
                            <div className="">
                                <img src={item.src} alt={t[item.value_title]} className="rounded-lg object-cover  w-[25rem] h-[17rem]" loading="lazy" decoding="async" />
                            </div>
                            <div>
                                <p className="text-lg text-start text-blue-800 font-semibold">
                                    {t[item.value_title]}
                                </p>
                                <p className="text-start text-[13px] text-gray-700">
                                    {t[item.value_description]}
                                </p>
                            </div>
                        </a>
                    ))}
                </div>
            </div>

        </div>
    )
}
export const Services = ({ t }) => {
    return (
        <div className="p-4 w-full flex justify-center" id="services">
            <div className="space-y-10 lg:w-3/4">
                <div className=" space-y-3 lg:w-1/2">
                    <Head title={t["head_4"]} />
                    <p className="text-[14px]">{t["paragraph_5"]}</p>
                </div>
                <div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-9">
                    {metadata["services"].map((item, index) => (
                        <div className="space-y-2">
                            <div className="">
                                <img  src={item.src} alt={t[item.value_title]} loading="lazy" className="rounded-lg object-cover w-[25rem] h-[17rem]" />
                            </div>
                            <div>
                                <p className="text-lg text-start text-blue-800 font-semibold">
                                    {t[item.value_title]}
                                </p>
                                <p className="text-start text-[13px] text-gray-700">
                                    {t[item.value_description]}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

        </div>
    )
}

export const Influencers = ({ t }) => {
    return (
        <div className="p-4 w-full lg:flex lg:justify-center" id="services">
            <div className="space-y-10 lg:w-3/4">
                <div className=" space-y-3 lg:w-1/2">
                    <Head title={t["head_6"]} />
                    <p className="text-[14px]">{t["paragraph_6"]}</p>
                </div>
                <div className="w-full flex justify-center">
                    <iframe className="relative w-full max-w-[560px] aspect-video cursor-pointer"src="https://www.youtube-nocookie.com/embed/45fuRrHwJus?si=U3sD7fauGxRQpFYH&amp;start=640"
                        title="Terry dang on Agadir Golden Trip" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>   
            </div>

        </div>
    )
}