import { NavBar } from '../components/NavBar';
import metadata from "../locals/metadata.json"

function NotFound({t}) {

  return (
    <div className='absolute overflow-x-hidden w-screen '>
      <div className='relative z-50'>
        <button className='bg-green-500 fixed bottom-3 right-3 rounded-full p-2'>
          <a href={metadata["social_links"][3].link} target='_blank'>
            <img src={metadata["social_links"][3].src} alt="Book vie WhatsApp" className='w-10 h-10' />
          </a>
        </button>
      </div>
      <NavBar t={t} />
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-sm text-center">
            <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-thirdary ">404</h1>
            <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">Something's missing.</p>
            <p className="mb-4 text-lg font-light text-thirdary">Sorry, we can't find that page. You'll find lots to explore on the home page. </p>
            <a href="/" className="inline-flex text-white bg-thirdary hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900 my-4">Back to Homepage</a>
        </div>   
    </div>
    </div>
  )
}

export default NotFound
