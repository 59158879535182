import LanguageSwitcher from "./LanguageSwitcher"
import metadata from "../locals/metadata.json"
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { Bars3Icon } from '@heroicons/react/24/outline'

export const NavBar = ({t, hide_cta}) => {
    return (
        <div className="bg-white p-2  min-h-[100px]">
            <header className="w-full flex justify-center">
                <nav className="flex justify-between items-center lg:w-[90%] w-full">
                    {/* Logo */}
                    <div>
                        <a href={window.location.href} className="flex justify-center space-x-2 items-center">
                            <img src="/logo.webp" alt="Agadir Golden Trip Logo" className="rounded-full w-10 h-10" />
                            <span className="text-thirdary font-bold">Agadir Golden Trip</span>
                        </a>
                    </div>
                    {/* Nav items */}
                    {/* Large device */}
                    <div className="hidden lg:flex space-x-2">
                        <ul className="flex items-center space-x-8 list-none">
                            {metadata.nav_list.map((item, index) => (
                                <li>
                                    <a id={index} href={"https://www.agadirgoldentrip.com" + t["lan"]+ item.href} className="text-thirdary hover:text-primary">
                                        {t[item.name]}
                                    </a>
                                </li>
                            ))}
                                <li>
                                    <a id={"thingstodo"} href={"https://www.agadirgoldentrip.com" + t["thingstodo_href"]} className="text-thirdary hover:text-primary">
                                        {t["activities"]}
                                    </a>
                                </li>
                        </ul>
                        <LanguageSwitcher languages={metadata["languages"]} />

                        {/*{!hide_cta && <Button title={t["cta"]}href={t["lan"] + metadata["cta_next"]} />}*/}
                    </div>
                    <div className="lg:hidden flex items-center pr-2 ">
                        <LanguageSwitcher t={t} languages={metadata["languages"]} />
                        <Menu as="div" className="relative ml-3">
                            <div>
                                <MenuButton className="relative flex rounded-full focus:outline-none">
                                    <span className="absolute -inset-1.5" />
                                    <span className="sr-only">Open user menu</span>
                                    <Bars3Icon aria-hidden="true" color="#00458b" className="block h-6 w-6 group-data-[open]:hidden " />

                                </MenuButton>
                            </div>
                            <MenuItems
                                transition
                                className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                            >
                                {metadata.nav_list.map((item, index) => (
                                    <MenuItem >
                                        <a id={index+1} href={"https://www.agadirgoldentrip.com/"+ item.href} className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100">
                                            {t[item.name]}
                                        </a>
                                    </MenuItem>

                                ))}
                                <MenuItem >
                                    <a id={0} href={"https://www.agadirgoldentrip.com/" + t["thingstodo_href"]} className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100">
                                        {t["activities"]}
                                    </a>
                                 </MenuItem>

                            </MenuItems>
                        </Menu>
                    </div>
                </nav>
            </header>
        </div>
    )
}