import { HelmetComponent } from './ArticleDetail'; // Ensure correct path or module import
import { NavBar } from '../components/NavBar';
import {Activities}  from '../pages/ThingsTodo';
import React from "react";
import { WhatsAppFloating } from '../components/WhatsAppFloating';

export const Attractions = ({ t,  page_title, slug, page_description, attraction, attraction_head, attraction_descriptio}) => {
  return (
    <div className="w-full h-full p-4">
      {/* Page Metadata */}
      <HelmetComponent page_title={page_title} slug={slug} page_description={page_description}/>
      {/* Floating WhatsApp Button */}
      <WhatsAppFloating />

      {/* Navbar */}
      <NavBar t={t} hide_cta={true}/>

      {/* Image Gallery */}
      <div className="flex justify-center w-full">
        <div className="flex justify-center lg:w-2/3 h-auto">
        <div className="space-y-4 pt-10">
            <h1 className="text-4xl text-thirdary font-medium w-full">{t[attraction_head]}</h1>
          <img loading="lazy" src={attraction["thumbnail"]} className="w-full h-[25rem] object-cover" alt={attraction["alt"]}/>
          <p>{t[attraction["description"]]}</p>
        <h1 className="text-2xl text-thirdary  font-semibold">{t["activities_to_explore"]}</h1>
        <Activities t={t} />

        </div>

        </div>

      </div>

    </div>
  );
};
