import { format } from 'date-fns-tz';

export function parseDate(dateString) {
    // Get the user's local timezone
    const targetTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Create a Date object from the dateString
    const date = new Date(dateString);

    // Extract year, day of the week, and formatted time
    const year = date.getFullYear();
    const options = { weekday: 'long' }; // 'long' for full name
    const dayOfWeek = date.toLocaleDateString(undefined, options); // Using user's locale
    const day = date.getDate();
    
    // Format the time to the user's timezone
    const userTimezoneTime = format(date, 'HH:mm', { timeZone: targetTimezone });

    // Combine into the desired format
    return `${year} ${day} ${dayOfWeek}  ${userTimezoneTime}`;
}

export function getItemsBy(content, key, value){
  return content.filter((block)=> block[key] === value)
}
