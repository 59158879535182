import { Head } from "./Heading"
import metadate from "../locals/metadata.json"
export const Reviews = ({ t }) => {
    function truncate(string) {
        if ("".length <= 300) {
            return string
        }
        return string.substring(0, 300) + "...."
    }
    return (
        // <div className="w-full flex justify-center p-4">
        <div className="p-4 w-full flex justify-center" id="reviews">
            <div className="space-y-10 lg:w-3/4">
                <div className="space-y-5">
                    <div className=" space-y-3 lg:w-1/2">
                        <Head title={t["head_3"]} />
                        <p className="text-[14px]">{t["paragraph_4"]}</p>
                    </div>

                    <div className="grid lg:grid-cols-3 gap-6 sm:grid-cols-2 ">

                        {metadate["reviews"].map((item, index) => (
                            <a className="space-y-4 bg-gray-100 rounded-lg p-3 h-fit" href={item.link} target="_blank">
                                <img loading="lazy" src={item.src} alt={`Review from ${item.website}`} className="w-6 h-6 rounedd-full" />
                                <div className="flex">
                                    {[...Array(5)].map((u, index) => (
                                        <svg class="star_solid" fill="#f5d60c" aria-hidden="true" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="-8 -8 584 520" className="w-4 h-4">
                                            <path d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z">
                                            </path>
                                        </svg>
                                    ))}
                                </div>
                                <p className="text-[12px]">{truncate(t[item.value_review])}</p>
                                <span className="font-semibold">{item.customer}</span>
                            </a>
                        ))}
                    </div>
                </div>
            </div>


        </div>
    )
}