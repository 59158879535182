import { NavBar } from '../components/NavBar';
import { About } from '../components/About';
import { Products, Influencers, Services } from '../components/Products';
import { Reviews } from '../components/Reviews';
import { ContactUs } from '../components/ContactUs';
import { Articles } from '../components/Articles';
import { Helmet } from 'react-helmet-async'; // or react-helmet if not using SSR
import { useEffect } from "react";

import metadata from "../locals/metadata.json"

function Home({ t, lan }) {
  useEffect(() => {
    // Ensure Facebook SDK is loaded
    if (window.FB) {
      window.FB.XFBML.parse();
    }
  }, []);

  return (
    <div className='absolute overflow-x-hidden w-screen '>
       <Helmet>
      {/* Set the page title */}
      <title>{t["page_title"]}</title>
      <link rel="canonical" href={`https://www.agadirgoldentrip.com/`}/>
      {/* Set meta tags */}
      <meta name="description" content={t["page_description"]} />
      <meta property="og:title" content={t["page_title"]} />
      <meta property="og:description" content={t["page_description"]} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:locale" content="en_US" />
      <link rel="alternate" hreflang="es" href="https://www.agadirgoldentrip.com/es"/>   
      <link rel="alternate" hreflang="x-default" href="https://www.agadirgoldentrip.com/"/>  
      <link rel="alternate" hreflang="fr" href="https://www.agadirgoldentrip.com/fr"/>  
    </Helmet>
      <div className='relative z-50'>
        <div className=' fixed bottom-3 right-3 rounded-full p-2'>
          <a href={metadata["social_links"][3].link} target='_blank' className=' p-2'>
            <img src={metadata["social_links"][3].src} alt="Book vie WhatsApp" className='w-12 h-12 bg-green-400 p-2 rounded-full' />
          </a>
        </div>
      </div>
      <NavBar t={t} />
      {/* <div className="w-full h-full flex justify-center">
        <div 
        className="fb-video flex justify-center" 
        data-href={"https://web.facebook.com/reel/922313153441918"} 
        data-width="500" 
        data-show-text="false"
      >
      </div>
      </div>   */}
                <div className="w-full flex justify-center">
                    <iframe className="relative w-full max-w-[560px] aspect-video cursor-pointer"src="https://www.youtube-nocookie.com/embed/4BLV9rWv_8Q?si=BdOzxZk0bgI5I9Fp"
                        title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>  
      <About t={t} />
      <div className='space-y-12 h-full'>
        <Influencers t={t}/>
        <Products t={t} />
        <Articles t={t} />
        <Services t={t} />
        <Reviews t={t} />
        <ContactUs t={t} />
      </div>
    </div>
  )
}

export default Home
