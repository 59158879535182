import { HelmetComponent } from './ArticleDetail'; // Ensure correct path or module import
import { NavBar } from '../components/NavBar';
import axios from 'axios';
import { WhatsAppFloating } from '../components/WhatsAppFloating';

import metadata from "../locals/metadata.json";
import React, { useState, useEffect } from "react";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useSearchParams  } from 'react-router-dom';
import { createClient } from '@supabase/supabase-js';
// Initialize Supabase
const supabaseUrl = import.meta.env.VITE_SUPABASE_URL; // Fix the typo in the environment variable name
const supabaseKey = import.meta.env.VITE_ANON;
const supabase = createClient(supabaseUrl, supabaseKey);

const Confirm = ({ basePrice, t }) => {
  const [numPeople, setNumPeople] = useState(1); // Default to 1 person
  const [ searchParam ] = useSearchParams()
  const [selectedDate, setSelectedDate] = useState(""); // Date selection state
  const [name, setName] = useState(""); // Name input state
  const [phone, setPhone] = useState(""); // Phone number input state
  const [email, setEmail] = useState(""); // Phone number input state
  const [country, setCountry] = useState(""); // Phone number input state
  const [option, setOption] = useState("shared"); // Private or Shared option
  const [IsLoading, setIsLoading] = useState(false); // Private or Shared option
  const [SuccessMessage, setSuccessMessage] = useState(""); // Private or Shared option

  useEffect(()=>{
    if(searchParam.get("defaultType")){
        console.log(searchParam.get("defaultType"))
        setOption(searchParam.get("defaultType"));
    }
  }, [])

  const handleNumPeopleChange = (e) => {
    const value = Math.min(Math.max(Number(e.target.value), 1), 25); // Clamp between 1 and 25
    setNumPeople(value);
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handleOptionChange = (e) => {
    setOption(e.target.value);
  };

  const handlePhoneChange = (value) => {
    setPhone(value);
  };

  const calculateTotalPrice = () => {
    if(option !=="shared"){
        return "Varie"
    }
    return `${numPeople} x ${basePrice} = ${basePrice * numPeople} MAD (` +t["approxmate"] + ')';
  };

  const isDateValid = () => {
    const today = new Date();
    const selected = new Date(selectedDate);
    return selected >= today; // Ensures the date is today or future
  };

  const isFormValid = () => {
    if(IsLoading){
        return false
    }
    return email.trim() && name.trim() && phone.trim() && phone.length >= 6 && selectedDate && isDateValid();
  };
const handlePostRequest = async () => {
    const requestBody = {
      type: option,
      full_name: name,
      num_people: numPeople,
      email: email,
      phone_number: phone,
      country:country
    };


    try {
      const response = await axios.post('https://edge-function-email-sender-production.up.railway.app/handle', requestBody, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

    } catch (error) {
      console.error('Error:', error);
    }
  };

  const ConfirmOrder = async()=>{
    setIsLoading(true);
    const response = await fetch("https://ipinfo.io/json");
    const ip_data = await response.json();
    // setCountry(ip_data.country)
    // const { data, error } = await supabase
    //   .from('Orders')
    //   .insert([
    //     { type: option, full_name: name, 
    //       num_people:numPeople, date:selectedDate, 
    //       ip_address:ip_data.ip,
    //       country:ip_data.country,
    //       city:ip_data.city,email:email,
    //       phone_number:phone },
    //   ])
    //   .select()

    // if(error){
    // }
    // else{
    handlePostRequest()
    alert(t["booked_message"])
    // }
    setIsLoading(false);
  }

  return (
    <div className="p-4 bg-blue-200 rounded-lg gap-3 w-fit text-sm grid sm:grid-cols-3 lg:grid-cols-3 grid-cols-1">
      {/* Name Input */}
      <div className="flex flex-col space-y-2">
        <label className="text-blue-950 font-medium" htmlFor="name">
          {t["name_label"]}
        </label>
        <input
          id="name"
          type="text"
          value={name}
          onChange={handleNameChange}
          placeholder={t["name_placeholder"]}
          className="border rounded-lg p-2"
        />
      </div>
      <div className="flex flex-col space-y-2">
        <label className="text-blue-950 font-medium" htmlFor="name">
          {t["email_label"]}
        </label>
        <input
          id="email"
          type="text"
          value={email}
          onChange={handleEmailChange}
          placeholder={t["name_placeholder"]}
          className="border rounded-lg p-2"
        />
      </div>
      {/* Phone Number Input */}
      <div className="flex flex-col space-y-2">
        <label className="text-blue-950 font-medium" htmlFor="phone">
          {t["phone_label"]}
        </label>
{/*        <input
          id="phone"
          type="tel"
          value={phone}
          onChange={handlePhoneChange}
          placeholder={t["phone_placeholder"]}
          className="border rounded-lg p-2"
        />*/}
     <PhoneInput
        country={'us'} // Set a default country
        value={phone}
        onChange={handlePhoneChange}
      />
      </div>

      {/* Number of People Input */}
      <div className="flex flex-col space-y-2">
        <label className="text-blue-950 font-medium" htmlFor="numPeople">
          {t["num_people_label"]}
        </label>
        <input
          id="numPeople"
          type="number"
          min="1"
          max="25"
          value={numPeople}
          onChange={handleNumPeopleChange}
          className="border rounded-lg p-2"
        />
      </div>
        <div className="flex flex-col space-y-2">
        <label className="text-blue-950 font-medium" htmlFor="option">
          {t["option_label"]}
        </label>
        <select
          id="option"
          value={option}
          onChange={handleOptionChange}
          className="border rounded-lg p-2"
        >
          <option value="shared">{t["shared_option"]}</option>
          <option value="private">{t["private_option"]}</option>
        </select>
      </div>
      {/* Date Selection */}
      <div className="flex flex-col space-y-2">
        <label className="text-blue-950 font-medium" htmlFor="date">
          {t["select_date_label"]}
        </label>
        <input
          id="date"
          type="date"
          value={selectedDate}
          onChange={handleDateChange}
          className="border rounded-lg p-2"
          min={new Date().toISOString().split("T")[0]} // Disable past dates
        />
      </div>

      {/* Total Price */}
      <div className="flex flex-col space-y-2 items-center">
        <h4 className="text-blue-950 font-semibold">
          {t["total_price_label"]}: {calculateTotalPrice()}
        </h4>
      </div>
      {/* Confirm Button */}
      <button
        onClick={() =>ConfirmOrder()}
        className={`bg-blue-900 text-white px-3 py-2 rounded-full flex justify-center items-center ${
          isFormValid()  ? "opacity-100" : "opacity-50 cursor-not-allowed"
        }`}
        disabled={!isFormValid()} // Disable button if form is invalid
      >
        {t["confirm_button"]}
      </button>

    </div>
  );
};



const ImageGallery = ({ images }) => {
  return (
    <div className="">
      {images.map((src, index) => (
        <div
          key={index}
          className={`hidden lg:block overflow-hidden bg-gray-100 ${
            index % 3 === 0
              ? "col-span-2 row-span-2 aspect-[4/3]"
              : "aspect-square"
          }`}
        >
          <img
            src={src}
            alt={`Image ${index + 1}`}
            className="w-full h-full object-cover"
          />
        </div>
      ))}
    </div>
  );
};
export const Services = ({ t }) => {
    return (
        <div className="w-full" id="services">
            <div className="space-y-10 ">
                <div className=" space-y-3 ">
                    <h3 className="text-thirdary font-medium text-xl">{t["head_4"]}</h3>
                    <p className="text-[14px]">{t["paragraph_6"]}</p>
                </div>
                <div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-9">
                    {metadata["services"].map((item, index) => (
                        <div className="space-y-2">
                            <div className="">
                                <img src={item.src} alt={t[item.value_title]} className="rounded-lg object-cover  w-[25rem] h-[17rem]" />
                            </div>
                            <div>
                                <h4 className="text-lg text-start text-blue-800 font-semibold">
                                    {t[item.value_title]}
                                </h4>
                                <p className="text-start text-[13px] text-gray-700">
                                    {t[item.value_description]}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

        </div>
    )
}
export const Booking = ({ t, product, page_title, slug, page_description}) => {
  return (
    <div className="w-full h-full p-4">
      {/* Page Metadata */}
      <HelmetComponent page_title={page_title} slug={slug} page_description={page_description}/>

      {/* Floating WhatsApp Button */}
      <WhatsAppFloating/>

      {/* Navbar */}
      <NavBar t={t} hide_cta={true}/>

      {/* Image Gallery */}
      <div className="flex justify-center w-full">
        <div className="flex justify-center lg:w-2/3 h-auto">
        <div className="space-y-4 pt-10">
            <h1 className="text-3xl text-thirdary font-medium w-full">{t[product["page_title"]]}</h1>
          <img src={product["thumbnail"]} className="w-full h-[25rem] object-cover"/>
          <div className="lg:flex justify-between w-full items-center lg:space-x-3">
            <div className="space-y-4">
                <p className="text-blue-950 text-[16px]">{t[product["page_description"]]}</p>
                <h3 className="text-thirdary font-medium text-xl">{t["what_included"]}</h3>
                <ul className="pl-7">
                    {product["services"].map((service, index)=>(
                        <li className="text-blue-950">{t[service]}</li>
                    ))}
                </ul>
            </div>

            <div>

            </div>
          </div>
                  <Confirm t={t} basePrice={500}/>

          <Services t={t}/>
        </div>

        </div>

      </div>


    </div>
  );
};
