import React from "react";
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'

const LanguageSwitcher = ({ languages }) => {

    return (
        <div className="flex justify-center items-center">
            <Menu as="div" className="relative ml-3 items-center">
                <div>
                    <MenuButton className="relative flex rounded-full focus:outline-none">
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">Open user menu</span>
                        <img src={languages[0].flag} alt="English flag" className="w-5 h-5" />

                    </MenuButton>
                </div>
                <MenuItems
                    transition
                    className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                >
                    {languages.map((item, index) => (
                        <MenuItem >
                            <button className="flex items-center p-2 hover:bg-gray-200 bg-none rounded-lg w-full " onClick={() => handleLanguageChange(index)}>
		               <a href={item.href} className="flex items-center space-x-1">
                                <img
                                    src={item.flag}
                                    alt={item.name}
                                    className="w-5 h-5"
                                    id={index} />
                                <p className="text-sm">{item.name}</p>
</a>

                            </button>

                        </MenuItem>
                    ))}

                </MenuItems>
            </Menu>
        </div>

    );
};

export default LanguageSwitcher;