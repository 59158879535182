import './App.css';
import Home from './pages/Home';
import {ArticleDetail} from './pages/ArticleDetail';
import NotFound from './pages/NotFound';

import { Booking } from './pages/Booking';
import { Activities } from './pages/Activities';
import { ThingsTodo } from './pages/ThingsTodo';
import { Attractions } from './pages/Attractions';
import translationEN from './locals/en/translation.json';
import translationES from './locals/es/translation.json';
import translationFR from './locals/fr/translation.json';
import metadata from './locals/metadata.json';

import { BrowserRouter, Routes, Route  } from "react-router-dom";
import { Analytics } from "@vercel/analytics/react"
import { HelmetProvider } from 'react-helmet-async';

function App() {
  return (
        <HelmetProvider>
        <div className=''>
       <Analytics/>
        <BrowserRouter>
        <Routes>
          <Route path="*" element={<NotFound t={translationEN}/>} />
          <Route path="/" element={<Home t={translationEN}  />} />
          <Route path="/en" element={<Home t={translationEN} />} />
          <Route path="/fr" element={<Home t={translationFR} lan={"fr"} />} />
          <Route path="/es" element={<Home t={translationES} lan={"es"} />} />
          <Route path={`/attractions/marina-agadir`}
                 element={<Attractions 
                              t={translationEN} 
                              attraction_head={"attraction_1_head"}
                              attraction={metadata["attraction_1"]}
                              page_title={"Marina Agadir: Everything you need to know"}
                              slug={"attractions/marina-agadir"}
                              page_description={"Everything you need to know about Marina of Agadir. Restuarants, Coffe shops, Yacht clubs, Jet Ski, Fly Board ,Bars and many more"}
                             />} />
          <Route path={`/fr-attractions/marina-agadir`}
                 element={<Attractions 
                              t={translationFR} 
                              attraction_head={"attraction_1_head"}
                              attraction={metadata["attraction_1"]}
                              page_title={"Marina Agadir: Tout ce qu'il faut savoir"}
                              slug={"fr-attractions/marina-agadir"}
                              page_description={"Tout ce que vous devez savoir sur la Marina d'Agadir. Restaurants, cafés, clubs nautiques, jet ski, fly board, bars et bien d'autres"}
                             />} />
          <Route path={`/atracciones/marina-agadir`}
                 element={<Attractions 
                              t={translationES} 
                              attraction_head={"attraction_1_head"}
                              attraction={metadata["attraction_1"]}
                              page_title={"Marina de Agadir: todo lo que necesitas saber"}
                              slug={"atracciones/marina-agadir"}
                              page_description={"Todo lo que necesitas saber sobre la Marina de Agadir. Restaurantes, cafeterías, clubes náuticos, motos acuáticas, flyboard, bares y mucho más."}
                             />} />
          <Route path={`/things-todo-in-agadir/agadir-jeep-safari-experience`}
                 element={<Activities 
                              t={translationEN} 
                              services_key={"services_2"}
                              page_title={"Agadir Jeep safari 4x4 Desert Adventures with Lunch"}
                              slug={"things-todo-in-agadir/agadir-jeep-safari-experience"}
                              product={metadata["products_offered"][1]}
                              cta={"https://www.viator.com/tours/Agadir/Agadir-Jeep-Safari-Experience/d4383-163063P6?pid=P00230849&uid=U00615858&mcid=58086&currency=USD"}
                              page_description={"Explore Agadir's stunning landscapes on a full-day 4x4 Jeep adventure. Visit a desert oasis, fishing village, beaches, and Tiznit, with a traditional Berber lunch and hassle-free hotel pickup."}
                             />} />
          <Route path={`/things-todo-in-agadir/agadir-jet-ski-marina`}
                 element={<Activities 
                              services_key={"services_3"}
                              t={translationEN} 
                              page_title={"Agadir Marina : 30 minutes of Adventure in Jet ski ride"}
                              slug={"things-todo-in-agadir/agadir-jet-ski-marina"}
                              product={metadata["products_offered"][2]}
                              cta={"https://wa.me/212610268581?text=I%20want%20to%20book%20a%20jetski%20in%20agadir%20marina%20%20Can%20you%20please%20give%20me%20more%20information?"}
                              page_description={"Speed over the clear coastal waters on a Jet Ski in Marina of Agadir with The Best prices"}
                             />} />
          <Route path={`/things-todo-in-agadir`}
                 element={<ThingsTodo 
                              t={translationEN} 
                              page_title={"The Best Things to do in Agadir"}
                              slug={"things-todo-in-agadir"}
                              page_description={"Top-Rated things to do in Agadir. Find the best activities. Book Online, Full refund. Enjoy incredible experiences."}
                             />} />
          <Route path={`/cosas-que-hacer-en-agadir`}
                 element={<ThingsTodo 
                              t={translationES} 
                              page_title={"Las majores cosas que hacer en Agadir"}
                              slug={"cosas-que-hacer-en-agadir"}
                              page_description={"Actividades mejor valoradas en Agadir. Encuentra las mejores actividades. Reserva en línea, reembolso completo. Disfruta de experiencias increíbles."}
                             />} />
         <Route path={`/cosas-que-hacer-en-agadir/safari-en-jeep-por-agadir`}
                 element={<Activities 
                              t={translationES} 
                               services_key={"services_2"}
                              page_title={"Safari en Jeep 4x4 por Agadir - Aventuras en el desierto con almuerzo"}
                              slug={"cosas-que-hacer-en-agadir/safari-en-jeep-por-agadir"}
                              product={metadata["products_offered"][1]}
                              cta={"https://www.viator.com/tours/Agadir/Agadir-Jeep-Safari-Experience/d4383-163063P6?pid=P00230849&uid=U00615858&mcid=58086&currency=USD"}
                              page_description={"Explora los impresionantes paisajes de Agadir en una aventura en Jeep 4x4 de un día completo. Visita un oasis del desierto, un pueblo pesquero, playas y Tiznit, con un almuerzo tradicional bereber y recogida en el hotel sin complicaciones."}
                             />} />
          <Route path={`/cosas-que-hacer-en-agadir/agadir-jet-ski-marina`}
                 element={<Activities 
                              services_key={"services_3"}
                              t={translationES} 
                              page_title={"Agadir Marina: 30 minutos de aventura en moto acuática"}
                              slug={"choses-a-faire-a-agadir/agadir-jet-ski-marina"}
                              product={metadata["products_offered"][2]}
                              cta={"https://wa.me/212610268581?text=I%20want%20to%20book%20a%20jetski%20in%20agadir%20marina%20%20Can%20you%20please%20give%20me%20more%20information?"}
                              page_description={"Navega a toda velocidad por las cristalinas aguas costeras en moto acuática en el puerto deportivo de Agadir con los mejores precios"}
                             />} /> 

          <Route path={`/choses-a-faire-a-agadir/agadir-jet-ski-marina`}
                 element={<Activities 
                              services_key={"services_3"}
                              t={translationFR} 
                              page_title={"Agadir Marina : 30 minutes d'aventure en Jet ski"}
                              slug={"choses-a-faire-a-agadir/agadir-jet-ski-marina"}
                              product={metadata["products_offered"][2]}
                              cta={"https://wa.me/212610268581?text=I%20want%20to%20book%20a%20jetski%20in%20agadir%20marina%20%20Can%20you%20please%20give%20me%20more%20information?"}
                              page_description={"Parcourez les eaux claires de la côte en jet ski dans la marina d'Agadir aux meilleurs prix"}
                             />} />         
         <Route path={`/choses-a-faire-a-agadir/safari-en-jeep-a-agadir`}
                 element={<Activities 
                              t={translationFR} 
                              services_key={"services_2"}

                              page_title={"Safari en Jeep 4x4 à Agadir - Aventures dans le désert avec déjeuner"}
                              slug={"choses-a-faire-a-agadir/safari-en-jeep-a-agadir"}
                              product={metadata["products_offered"][1]}
                              cta={"https://www.viator.com/tours/Agadir/Agadir-Jeep-Safari-Experience/d4383-163063P6?pid=P00230849&uid=U00615858&mcid=58086&currency=USD"}
                              page_description={"Explorez les paysages époustouflants d'Agadir lors d'une aventure en Jeep 4x4 d'une journée complète. Visitez une oasis du désert, un village de pêcheurs, des plages et Tiznit, avec un déjeuner traditionnel berbère et une prise en charge à l'hôtel sans souci."}
                             />} />
          <Route path={`/choses-a-faire-a-agadir`}
                 element={<ThingsTodo 
                              t={translationFR} 
                              page_title={"Les meilleures choses à faire à Agadir"}
                              slug={"fr/choses-a-faire-a-agadir"}
                              page_description={"Activités les mieux notées à Agadir. Trouvez les meilleures activités. Réservez en ligne, remboursement complet. Profitez d'expériences incroyables."}
                             />} />

          <Route path={`/book/agadir-boat-cruise-with-fishing-meals-included-and-transfer-service`}
                 element={<Booking 
                              t={translationEN} 
                              page_title={"Agadir: Boat cruise with fishing, meals included and transfer service"}
                              slug={"book/agadir-boat-cruise-with-fishing-meals-included-and-transfer-service"}
                              product={metadata["products_offered"][0]}
                              page_description={"Embark on an unforgettable boat cruise in Agadir, featuring exciting fishing experiences, delicious meals, and convenient transfer services. Perfect for families, couples, and adventurers seeking relaxation and fun on the water"}
                             />} />

          <Route path={`/fr/book/croisiere-en-bateau-a-agadir-avec-peche-repas-inclus-et-service-de-transfert`}
                 element={<Booking 
                              t={translationFR} 
                              page_title={"Agadir : Croisière en bateau avec pêche, repas inclus et service de transfert"}
                              slug={"fr/book/croisiere-en-bateau-a-agadir-avec-peche-repas-inclus-et-service-de-transfert"}
                              product={metadata["products_offered"][0]}
                              page_description={"Embarquez pour une croisière en bateau inoubliable à Agadir, avec des expériences de pêche passionnantes, de délicieux repas et des services de transfert pratiques. Parfait pour les familles, les couples et les aventuriers en quête de détente et de plaisir sur l'eau."}
                             />} />
          <Route path={`/es/book/crucero-en-barco-por-agadir-con-pesca-comidas-incluidas-y-servicio-de-traslado`}
                 element={<Booking 
                              t={translationES} 
                              page_title={"Agadir: Crucero en barco con pesca, comidas incluidas y servicio de transportee"}
                              slug={"es/book/crucero-en-barco-por-agadir-con-pesca-comidas-incluidas-y-servicio-de-traslado"}
                              product={metadata["products_offered"][0]}
                              page_description={"Embárcate en un inolvidable crucero en barco en Agadir, con emocionantes experiencias de pesca, deliciosas comidas y servicios de transporte convenientes. Perfecto para familias, parejas y aventureros que buscan relajación y diversión en el agua."}
                             />} />

          <Route path="/es/reservar" element={<Booking t={translationES} />} />
          <Route path="/fr/reservez" element={<Booking t={translationFR} />} />

          <Route path="/articles/Your-Ultimate-Guide-to-Moroccan-Tourism" 
                element={<ArticleDetail slug={"Your-Ultimate-Guide-to-Moroccan-Tourism"} 
                                        t={translationEN}
                                        page_description={"Explore Agadir, Morocco, with stunning beaches, thrilling activities, rich culture, and delicious cuisine. Your ultimate guide to unforgettable experiences awaits!"}
                                        page_title={"Your Ultimate Guide to Moroccan Tourism"} />}/>

          <Route path="/articles/uncover-the-wonders-of-morocco-with-the-agadir-golden-trip-excursion" 
                element={<ArticleDetail slug={"uncover-the-wonders-of-morocco-with-the-agadir-golden-trip-excursion"} 
                                        t={translationEN}
                                        page_description="Experience the best of Agadir's coastline with personalized boat tours, including private excursions, fishing trips, and sunset cruises, all designed for unforgettable adventures on the water!"
                                        page_title="Uncover The wonders of Morocco with The Agadir Golden Trip Excursion"/>} />
          <Route path="/articles/votre-guide-ultime-du-tourisme-marocain" 
                element={<ArticleDetail slug={"votre-guide-ultime-du-tourisme-marocain"} 
                                        t={translationFR}
                                        page_description=" Explorez Agadir, au Maroc, avec ses plages magnifiques, ses activités palpitantes, sa culture riche et sa cuisine délicieuse. Votre guide ultime pour des expériences inoubliables vous attend !!"
                                        page_title="Votre Guide Ultime Du Tourisme Marocain"/>} />
          <Route path="/articles/les-meilleures-excursions-en-bateau-a-agadir-pour-les-couples-et-les-familles" 
                element={<ArticleDetail slug={"les-meilleures-excursions-en-bateau-a-agadir-pour-les-couples-et-les-familles"} 
                                        t={translationFR}
                                        page_description="Réservez une excursion en bateau à Agadir pour offrir à votre famille ou à votre couple une expérience unique et inoubliable en mer !"
                                        page_title="Les Meilleures Excursions en Bateau à Agadir pour les Couples et les Familles"/>} />
          <Route path="/articles/decouvrez-les-meilleures-aventures-marines-a-agadir-peche-balades-en-bateau" 
                element={<ArticleDetail slug={"decouvrez-les-meilleures-aventures-marines-a-agadir-peche-balades-en-bateau"} 
                                        t={translationFR}
                                        page_description="Explorez Agadir avec une journée pêche pour du poisson frais, des boat trips le long du littoral, ou des croisières depuis la Marina d'Agadir.  Confiez vos aventures à une agence excursion Agadir."
                                        page_title="Découvrez les Meilleures Aventures Marines à Agadir : Pêche, Balades en Bateau et Excursions"/>} />
          <Route path="/articles/the-10-best-things-to-do-in-agadir" 
                element={<ArticleDetail slug={"the-10-best-things-to-do-in-agadir"} 
                                        t={translationEN}
                                        page_description="Top things to do in Agadir: 1.Souk Alhad, 2.Explore beaches , 3.Croparc, 4.Excursion on Boat"
                                        page_title="The 10 Best Things To Do in Agadir"/>} />  
          <Route path="/articles/les-10-meilleures-choses-a-faire-a-agadir" 
                element={<ArticleDetail slug={"les-10-meilleures-choses-a-faire-a-agadir"} 
                                        t={translationFR}
                                        page_description="Les meilleures choses à faire à Agadir :   1. Souk El Had   2. Explorer les plages   3. Crocoparc   4. Excursion en bateau"
                                        page_title="Les 10 meilleures choses à faire à Agadir"/>} />  
          <Route path="/articles/les-meilleures-locations-de-bateaux-a-agadir-guide-complet" 
                element={<ArticleDetail slug={"les-meilleures-locations-de-bateaux-a-agadir-guide-complet"} 
                                        t={translationFR}
                                        page_description="Les Meilleures Locations de Bateaux à Agadir : Guide Complet"
                                        page_title="Les 10 meilleures choses à faire à Agadir"/>} /> 
          <Route path="/articles/les-meilleures-locations-de-bateaux-a-agadir-guide-complet" 
                element={<ArticleDetail slug={"agadir"} 
                                        t={translationEN}
                                        page_description="Agadir is a modern Moroccan coastal city rebuilt after the 1960 earthquake. With its golden beaches, lively markets like Souk El Had, and rich Amazigh heritage, it offers a perfect blend of history, culture, and outdoor adventure."
                                        page_title="Agadir"/>} />    
          <Route path="/articles/best-things-to-do-in-marrakech-a-complete-travel-guide" 
                element={<ArticleDetail slug={"best-things-to-do-in-marrakech-a-complete-travel-guide"} 
                                        t={translationEN}
                                        page_description="Discover the best things to do in Marrakech and Agadir, from vibrant souks to stunning beaches, for an unforgettable Moroccan adventure!"
                                        page_title="Best Things to Do in Marrakech: A Complete Travel Guide"/>} />        
        </Routes>

      </BrowserRouter>
   </div>
</HelmetProvider>

  )
}

export default App
