import { HelmetComponent } from './ArticleDetail'; // Ensure correct path or module import
import { NavBar } from '../components/NavBar';
import metadata from "../locals/metadata.json";
import React from "react";
import { WhatsAppFloating } from '../components/WhatsAppFloating';



const Confirm = ({ currency, basePrice, t, cta }) => {
  function onClick (){
    window.location.href = cta
  }
  return (<div className="w-full bg-blue-200 p-2 rounded-lg sm:w-1/2 lg:w-1/2 flex justify-center h-full items-center">
    <div className="text-center space-y-3">
      <p className="text-xs ">{t["price_from"]}</p>
    <p className="text-2xl font-medium">{currency}{" "}{basePrice}</p>
    <button onClick={()=>onClick()} className="bg-blue-600 rounded-full text-sm px-6 py-2 text-white">{t["confirm_button"]}</button>
          <p className="text-xs ">{t["no_charge_yet"]}</p>

    </div>
  </div>)
};

export const Services = ({ t, services }) => {
    return (
        <div className="w-full" id="services">
            <div className="space-y-10 ">
                <div className=" space-y-3 ">
                    <h3 className="text-thirdary font-medium text-xl">{t["head_4"]}</h3>
                    <p className="text-[14px]">{t["paragraph_6"]}</p>
                </div>
                <div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-9">
                    {services.map((item, index) => (
                        <div className="space-y-2">
                            <div className="">
                                <img src={item.src} alt={t[item.value_title]} className="rounded-lg object-cover  w-[25rem] h-[17rem]" />
                            </div>
                            <div>
                                <h4 className="text-lg text-start text-blue-800 font-semibold">
                                    {t[item.value_title]}
                                </h4>
                                <p className="text-start text-[13px] text-gray-700">
                                    {t[item.value_description]}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

        </div>
    )
}
export const Activities = ({ t, product, page_title, slug, page_description, cta, services_key}) => {
  return (
    <div className="w-full h-full p-4">
      {/* Page Metadata */}
      <HelmetComponent page_title={page_title} slug={slug} page_description={page_description}/>
      {/* Floating WhatsApp Button */}
      <WhatsAppFloating />

      {/* Navbar */}
      <NavBar t={t} hide_cta={true}/>

      {/* Image Gallery */}
      <div className="flex justify-center w-full">
        <div className="flex justify-center lg:w-2/3 h-auto">
        <div className="space-y-4 pt-10">
            <h1 className="text-3xl text-thirdary font-medium w-full">{t[product["page_title"]]}</h1>
          <img loading="lazy" src={product["thumbnail"]} className="w-full h-[25rem] object-cover"/>
          <div className="lg:flex justify-between w-full lg:space-x-3 items-center h-fit">
            <div className="space-y-4">
                <p className="text-blue-950 text-[16px]">{t[product["page_description"]]}</p>
                <h3 className="text-thirdary font-medium text-xl">{t["what_included"]}</h3>
                <ul className="pl-7">
                    {product["services"].map((service, index)=>(
                        <li className="text-blue-950">{t[service]}</li>
                    ))}
                </ul>
            </div>
            <Confirm currency={product["currency"]} basePrice={product["price"]} t={t} cta={cta}/>

            <div>

            </div>
          </div>
          <Services t={t} services={metadata[services_key]}/>
        </div>

        </div>

      </div>


    </div>
  );
};
