// src/contentfulClient.js
import { createClient } from 'contentful';

const client = createClient({
  space: import.meta.env.VITE_SPACE, // Your Contentful Space ID
  environment: import.meta.env.VITE_ENV, // Typo fixed: changed "enviroment" to "environment"
  accessToken: import.meta.env.VITE_ACCESS_TOKEN, // Typo fixed: changed "VITE_ACEESS_TOKEN" to "VITE_ACCESS_TOKEN"
});

export default client;
