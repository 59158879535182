import metadata from "../locals/metadata.json";

export const WhatsAppFloating =()=>{
  return (
    <div className="relative z-50">
  <a className="fixed bottom-5 right-5 rounded-full p-4 md:p-6 flex justify-center items-center"href={metadata["social_links"][3]?.link} target="_blank" rel="noopener noreferrer">
    <img
      src={metadata["social_links"][3]?.src}
      alt="Book via WhatsApp"
      className="w-10 h-10"
    />
  </a>
      </div>)
}