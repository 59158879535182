import metadata from "../locals/metadata.json";
import { NavBar } from '../components/NavBar';
import { ArticleItem } from '../components/Articles';

import client from '../contentfulClient';
import React, { useEffect, useState } from 'react';
import {parseDate, getItemsBy} from "../helpers"
import { Helmet } from 'react-helmet-async'; // or react-helmet if not using SSR

export function HelmetComponent ({page_title, page_description, slug}){
  return  <Helmet>
      {/* Set the page title */}
      <title>{page_title}</title>
      <link rel="canonical" href={`https://www.agadirgoldentrip.com/${slug}`}/>
      <meta property="og:url" content={`https://www.agadirgoldentrip.com/${slug}`} />
      {/* Set meta tags */}
      <meta name="description" content={page_description} />
      <meta property="og:title" content={page_title} />
      <meta property="og:description" content={page_description} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="en_US" />
      <link rel= "alternate" hreflang="en" href={`https://www.agadirgoldentrip.com/${slug}`} /> 
    </Helmet>
}

export default function ArticleContent({ article, t }) {

  const renderNestedContent = (content, nodeType, index)=>{
      switch (nodeType) {
        case 'unordered-list':
            return (
              <ul key={index} className="list-dis pl-6 mb-4">
                {content.content.map((listItem, listIndex) =>
                  renderNestedContent(listItem.content, listItem.nodeType, index)
                )}
              </ul>
            );
        case 'list-item':
            return (
              <li key={index+1} className="mb-2">
                {content.map((paragraph, paragraphIndex) =>
                  renderNestedContent(paragraph.content, paragraph.nodeType, index+1)
                )}
              </li>
            );
        case 'paragraph':
          return (
          
            <p key={index} className="my-4 leading-relaxed">
              {content.map((textNode, textIndex) =>
                renderTextNode(textNode, textIndex)
              )}
              
            </p>
          );
        default:
          return null;
      }
  }

  const renderContent = (content) => {
    return content.map((block, index) => {
      switch (block.nodeType) {

        case 'heading-2':
          return (
            <h2 key={index} className="font-semibold text-2xl my-6 text-gray-800" id={block.content[0].value}>
              {block.content.map((textNode, textIndex) =>
                renderTextNode(textNode, textIndex)
              )}
            </h2>
          );
        case 'heading-3':
          return (
            <h3 key={index} className="font-medium text-xl my-5 text-gray-800" id={block.content[0].value}>
              {block.content.map((textNode, textIndex) =>
                renderTextNode(textNode, textIndex)
              )}
            </h3>
          );
        case 'embedded-asset-block':
            return (
                <img src={block.data.target.fields.file.url} 
                     className="rounded-lg"
                    alt={block.data.target.fields.file.title}/>)
        case 'unordered-list':
            return (
              <ul key={index} className="list-dis pl-6 mb-4">
                {block.content.map((listItem, listIndex) =>
                  renderNestedContent(listItem.content, listItem.nodeType, index)
                )}
              </ul>
            );
        case 'list-item':
            return (
              <li key={index+1} className="mb-2">
                {block.content.map((paragraph, paragraphIndex) =>
                  renderNestedContent(paragraph.content, listItem.nodeType, index+1)
                )}
              </li>
            );
        case 'paragraph':
          return (
            <p key={index} className="my-4 leading-relaxed ">
              {block.content.map((textNode, textIndex) =>
                renderTextNode(textNode, textIndex)
              )}
            </p>
          );
        default:
          return null;
      }
    });
  };

  const renderTextNode = (textNode, index) => {
    if (textNode.nodeType === 'text') {
      const isBold = textNode.marks.some((mark) => mark.type === 'bold');
      return isBold ? (
        <strong key={index} className="font-semibold text-black">{textNode.value}</strong>
      ) : (
        <span key={index}>{textNode.value}</span>
      );
    }
    if (textNode.nodeType === 'hyperlink') {
      return (
        <a href={textNode.data.uri} key={index} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
          {textNode.content.map((subNode, subIndex) =>
            renderTextNode(subNode, subIndex)
          )}
        </a>
      );
    }
    return null;
  };

  return <div className=" lg:p-3 space-y-4">
                 
            <h1 className="text-3xl font-bold text-gray-800">{article.fields.title}</h1>
          <p className="text-gray-500 mt-2">{parseDate(article.sys.updatedAt)}</p>
 <div className="flex space-x-3 items-center">
            <img loading="lazy" src={article.fields.writerPic.fields.file.url} 
                            alt={article.fields.writerPic.fields.file.title}
                            className="rounded-full w-10 h-10 " />
            <p className="text-sm">{article.fields.writer}</p>
          </div>

            <img src={article.fields.thumbnail.fields.file.url} 
                            alt={article.fields.thumbnail.description}
                            className="rounded-lg w-full h-[33rem] object-cover"/>
            {renderContent(article.fields.content.content)}
            <div className="flex justify-center w-full text-[26px]">. . . . . . . . . . .</div>
            <div className="pt-16 space-y-4">
                <h4 className="text-2xl font-medium">{t["recommanded"]}</h4>
                <ul className="list-none grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-5">
                {getItemsBy(article.fields.content.content, "nodeType", "embedded-entry-block").map((article, index)=>(
            <ArticleItem article={article.data.target}/>
    ))}
</ul>
            </div>



    </div>;
}

export const ArticleDetail = ({ t ,  page_title, page_description,slug}) => {
  console.log("page title", page_title)
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchArticle = async () => {
      setLoading(true);
      try {
        const response = await client.getEntries({
          content_type: import.meta.env.VITE_CONTENT_TYPE,
          'fields.slug[match]': slug,
        });
        
        if (response.items.length > 0) {
            console.log(response.items[0])
          setArticle(response.items[0]);
        } else {
          setError('Article not found');
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchArticle();
  }, [slug]);

  if (loading) return <div><HelmetComponent page_title={page_title} page_description={page_description} slug={"article/"+slug}/><div className="flex justify-center py-10">Loading article...</div></div>;
  if (error) return <div><HelmetComponent page_title={page_title} page_description={page_description} slug={"article/"+slug}/><div className="text-center text-red-500 py-10">Error: {error.message}</div></div>;

  return (
    <div className='absolute overflow-x-hidden w-screen '>
      <HelmetComponent page_title={page_title} page_description={page_description} slug={"article/"+slug}/>
      <div className='relative z-50'>
        <button className='bg-green-500 fixed bottom-3 right-3 rounded-full p-2'>
          <a href={metadata["social_links"][3].link} target='_blank'>
            <img loading="lazy" src={metadata["social_links"][3].src} alt="Book vie WhatsApp" className='w-10 h-10' />
          </a>
        </button>
      </div>
      <NavBar t={t} />
      <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg mt-12">
        <ArticleContent article={article} t={t} />
      </div>
    </div>
  );
};


// export const ArticleDetail = ({ t, article }) => {
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   return (
//     <div className='absolute overflow-x-hidden w-screen '>
//       <div className='relative z-50'>
//         <button className='bg-green-500 fixed bottom-3 right-3 rounded-full p-2'>
//           <a href={metadata["social_links"][3].link} target='_blank'>
//             <img src={metadata["social_links"][3].src} alt="Book vie WhatsApp" className='w-10 h-10' />
//           </a>
//         </button>
//       </div>
//       <NavBar t={t} />
//       <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg mt-12">
//         <ArticleContent article={article} t={t} />
//       </div>
//     </div>
//   );
// };

