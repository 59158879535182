import { Head } from "./Heading"

import React, { useEffect, useState } from 'react';
import client from '../contentfulClient';
import {parseDate} from "../helpers"

export const ArticleItem = ({article})=>{
    return (
        <li key={article.sys.id} className="p-4 bg-blue-50 rounded-lg w-full">
                      <a href={`/articles/${article.fields.slug}`} className="space-y-1">
                        <img loading="lazy" src={article.fields.thumbnail.fields.file.url} 
                            alt={`${article.fields.title} article Thumbnail`}
                            className="rounded-lg object-cover w-full h-32"/>
                     <div className="flex space-x-2 items-center">
                        <img  loading="lazy" src={article.fields.writerPic.fields.file.url} 
                            alt={`Author ${article.fields.writer}`}
                            className="rounded-full w-6 h-6 object-cover" />
                    <p className="text-[10px]">{article.fields.writer}</p>

                  </div>
                                          <p className="text-[10px]">{parseDate(article.sys.updatedAt)}</p>

                        <h3 className="font-semibold text-[15px]">{article.fields.title}</h3>
   
                <p className="text-[13px]">{article.fields.excerpt.slice(0, 100)}...</p>
                    </a>
                      </li>)
}
export const Articles = ({ t }) => {

  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchArticles = async () => {
        client.getEntries()
        .then((response) =>{setArticles(response.items);setLoading(false);console.log(response.items)}
)
        .catch(console.error)

    };

    fetchArticles();
  }, []);


  useEffect(() => {
    document.title = t["page_title"]; // Set your desired title here
}, []);
    return (
        <div className="p-4 w-full flex justify-center" id="articles">
            <div className="space-y-10 lg:w-3/4">
                <div className=" space-y-3 lg:w-1/2">
                    <Head title={t["head_7"]} />
                    <p className="text-[14px]">{t["paragraph_7"]}</p>
                </div>
                {loading ? <div>Loading articles...</div>:<div className="">
                    <ul className="list-none grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-9">
                    {articles.slice(0, 4).map(article => (
                      <ArticleItem article={article}/>
                    ))}
                  </ul>
                </div>}
                
            </div>

        </div>
    )
}